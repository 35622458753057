<template>
  <v-container class="hq-jobs">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="5"
        offset-md="1"
        offset-lg="2"
        ref="image"
        class="pr-md-4"
      >
        <v-img
          max-width="100%"
          max-height="100%"
          height="100%"
          min-height="300px"
          :lazy-src="getLazySrc(image)"
          :src="`/media/${image.id}`"
          :aspect-ratio="getAspectRatio(image)"
          cover
          transition="fade-transition"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="5"
        lg="4"
      >
        <div class="title" ref="title">
          <h2 class="h1-big -sci-gradient d-inline-block">{{country}}</h2>
        </div>
        <p ref="text">
          {{name}} <br/>
          {{address}}
        </p>
        <!-- phone -> {{headquarter.phone}} <br/>
        email -> {{headquarter.email}} <br/> -->
        <MarkdownBlock
          ref="jobsIntro"
          linkify
          tag="div"
          :inline="false"
          :text="jobsIntro || 'Positions currently offered'"
        />
        <ul v-if="jobs && jobs.length > 0">
          <li
            v-for="job in jobs"
            :key="job.id"
            ref="jobs"
          >
            <Cta action="send-email" :subject="job.mailSubject" :mail="job.mailTo" :label="job.title"/>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ScrollAppear from '@/animations/gsap/scroll-appear'
import Cta from '@/molecules/Cta'

export default {
  name: 'HeadquarterJobs',
  components: { Cta, MarkdownBlock },
  props: {
    image: { type: Object },
    country: { type: String },
    name: { type: String },
    address: { type: String },
    jobs: { type: Array, default: () => [] },
    jobsIntro: { type: String }
  },
  mounted () {
    this.$nextTick(() => {
      this.tls = []
      this.tls.push(ScrollAppear(this.$refs.image))
      this.tls.push(ScrollAppear(this.$refs.title))
      this.tls.push(ScrollAppear(this.$refs.text))
      this.tls.push(ScrollAppear(this.$refs.jobsIntro.$el))
      if (this.$refs.jobs && this.$refs.jobs.length > 0) {
        this.tls.push(ScrollAppear(this.$refs.jobs))
      }
    })
  },
  beforeDestroy () {
    this.tls.forEach(tl => tl.kill())
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio
  }
}
</script>

<style lang="scss" scoped>
.hq-jobs {
  margin-bottom: rem(150px);
}

ul {
  list-style: none;
  padding: 0;
}

.title {
  transform: translate(0%, -50%);
  @include breakpoint('sm-and-up') {
    transform: translate(-50%, -50%);
  }
}
</style>
