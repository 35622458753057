<template>
  <BasePage :isLoaded="isLoaded">
    <template v-slot:content>
      <TitleTextAndImage
        :title="Careers.introTitle"
        :text="Careers.introDescription"
        :image="Careers.introImage"
      />
      <TitleAndText
        class="pb-10"
        :title="Careers.sliderTitle"
        :text="Careers.sliderDescription"
      />
      <Gallery
        class="pt-0"
        :slides="Careers.slider"
      />
      <TitleAndText
        :title="Careers.jobsTitle"
        :text="Careers.jobsDescription"
      />
      <HeadquarterJobs
        v-for="headquarter in Headquarters.items"
        :key="headquarter.id"
        v-bind="headquarter"
      />
    </template>
  </BasePage>
</template>

<script>
import { optimizedImageURL } from '@/services/OptimizedImage'
import ListHeadquarter from '@/graphql/ListHeadquarter.gql'
import GetCareers from '@/graphql/GetCareers.gql'
import BasePage from '@/organisms/BasePage'
import TitleTextAndImage from '@/atoms/TitleTextAndImage'
import TitleAndText from '@/molecules/TitleAndText'
import Gallery from '@/molecules/Gallery'
import HeadquarterJobs from '@/molecules/HeadquarterJobs'

export default {
  name: 'CareersPage',

  components: {
    BasePage,
    TitleTextAndImage,
    TitleAndText,
    Gallery,
    HeadquarterJobs
  },
  apollo: {
    Headquarters: {
      query: ListHeadquarter,
      variables () {
        return {
          sortOn: 'orderPosition',
          descending: false
        }
      }
    },
    Careers: {
      query: GetCareers,
      variables () {
        return {
          slug: 'careers'
        }
      }
    }
  },
  computed: {
    isLoaded () {
      return !!(this.Careers && this.Headquarters && this.Headquarters.items)
    }
  },

  metaInfo: function () {
    if (this.Careers) {
      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.Careers.metaTitle },
        { vmid: 'description', name: 'description', content: this.Careers.metaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.Careers.metaDescription }
      ]

      if (this.Careers.metaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.Careers.metaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.Careers.metaTitle,
        meta
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
