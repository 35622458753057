<template>
  <section>
    <div class="bg-1"></div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          offset-md="1"
          offset-lg="2"
        >
          <div ref="title">
            <h2 :class="gradientClass">{{ title }}</h2>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="5"
          offset-md="1"
          lg="4"
          class="mt-sm-16"
        >
          <MarkdownBlock
            ref="text"
            linkify
            tag="div"
            :inline="false"
            :text="text"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col
          cols="12"
          sm="4"
          offset-sm="2"
          offset-md="3"
        >
          <div ref="image">
            <v-img
              :aspect-ratio="3/4"
              :lazy-src="getLazySrc(image)"
              :src="optimizedImageURL(image, { width: 600, quality: 95 })"
              contain
              max-height="400px"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import GradientClass from '@/mixins/GradientClass'
import ScrollAppear from '@/animations/gsap/scroll-appear'
import { MarkdownBlock } from '@monogrid/vue-lib'
import { getLazySrc, optimizedImageURL } from '@/services/OptimizedImage'

export default {
  name: 'titleAndText',
  mixins: [GradientClass],
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      required: true
    }
  },
  components: {
    MarkdownBlock
  },
  mounted () {
    this.$nextTick(() => {
      this.tls = []
      this.tls.push(ScrollAppear(this.$refs.title))
      this.tls.push(ScrollAppear(this.$refs.text.$el))
      this.$refs.image && this.tls.push(ScrollAppear(this.$refs.image))
    })
  },
  beforeDestroy () {
    this.tls.forEach(tl => tl.kill())
  },
  methods: {
    getLazySrc,
    optimizedImageURL
  }
}
</script>
<style lang="scss" scoped>
.bg-1 {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: rem(500px);
  height: rem(500px);
  background: $bg-emo-gradient-3;
  transform: translate(-50%, 20%);
  pointer-events: none;
}
</style>
